@font-face {
  font-family: Saans;
  src: url("Saans-Regular.65e545a8.woff2") format("woff2"), url("Saans-Regular.dec62100.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Saans;
  src: url("Saans-Medium.cdb5134d.woff2") format("woff2"), url("Saans-Medium.494c8a97.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Saans;
  src: url("Saans-Bold.cbbfd0d7.woff2") format("woff2"), url("Saans-Bold.3ef99004.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}
/*# sourceMappingURL=index.b4dac96a.css.map */
